import axios from "axios";
import { getAuth } from "firebase/auth";

import { formatUser } from "./helperFunctions";
const baseUrl = require("../../shared.js").default.apiBaseUrl;
const { nonPersistentStore } = require("../../store/store");

const updateUser = async (userId, data) => {
  try {
    if (!userId) {
      throw new Error("No user id provided");
    }
    data.ID = userId;
    const urlToQuery = `${baseUrl}/user`;
    await axios.patch(urlToQuery, data);
  } catch (err) {
    console.log(err);
    return false;
  }
};

const getUser = async (req) => {
  try {
    const { userId, email, uid } = req;

    let urlToQuery = "";

    if (!userId && !email && !uid) {
      throw new Error("No parameters provided");
    }

    const userInStore = nonPersistentStore.state.currentUser;

    if (userId) {
      urlToQuery = `${baseUrl}/user?ID=${userId}`;
    } else if (email) {
      urlToQuery = `${baseUrl}/user?email=${email}`;
    } else if (uid) {
      urlToQuery = `${baseUrl}/user?uid=${uid}`;
    }
    const axiosResponse = await axios.get(urlToQuery);
    const { data } = axiosResponse;

    const dataHasKeys = data && Object.keys(data).length > 0;
    if (!dataHasKeys) {
      throw new Error("No matching user found");
    } else {
      const formattedUser = formatUser(data);
      if (!userInStore || userInStore?.ID === formattedUser.ID) {
        nonPersistentStore.commit("addOrUpdateUserData", formattedUser);
      }
      return formatUser(formattedUser);
    }
  } catch (err) {
    console.log(err);
    return undefined;
  }
};

const getOtherUser = async (userId) => {
  try {
    if (!userId) {
      throw new Error("No user id provided");
    }
    const urlToQuery = `${baseUrl}/user/other?ID=${userId}`;
    const axiosResponse = await axios.get(urlToQuery);
    const { data } = axiosResponse;
    return data;
  } catch (err) {
    console.log(err);
    return undefined;
  }
};

const createUser = async (createData) => {
  try {
    const urlToQuery = `${baseUrl}/user`;
    const axiosResponse = await axios.post(urlToQuery, createData);
    const { data } = axiosResponse;
    return formatUser(data);
  } catch (err) {
    console.log(err);
    return false;
  }
};

// To do later

const getAllUsers = async () => {
  try {
    const urlToQuery = `${baseUrl}/admin/all-users`;
    const axiosResponse = await axios.get(urlToQuery);
    const { data } = axiosResponse;
    return data.map(formatUser);
  } catch (err) {
    console.log(err);
    return [];
  }
};

const getAllUsersInDateRange = async (startDate, endDate) => {
  try {
    let allUsers = await getAllUsers();

    if (startDate) {
      allUsers = allUsers.filter((user) => {
        return user.creationTime && user.creationTime >= startDate;
      });
    }
    if (endDate) {
      allUsers = allUsers.filter((user) => {
        return user.creationTime && user.creationTime <= endDate;
      });
    }

    return allUsers;
  } catch (err) {
    console.log(err);
    return [];
  }
};

const checkIfUserExistsByEmail = async (email) => {
  try {
    const axiosEndpoint = `${baseUrl}/user-exists?email=${email}`;
    const axiosResponse = await axios.get(axiosEndpoint);
    const { data } = axiosResponse;
    return data;
  } catch (err) {
    console.log(err);
    return false;
  }
};

const checkIfUserExistsByUid = async (uid) => {
  try {
    const axiosEndpoint = `${baseUrl}/user-exists?uid=${uid}`;
    const axiosResponse = await axios.get(axiosEndpoint);
    const { data } = axiosResponse;
    return data;
  } catch (err) {
    console.log(err);
    return false;
  }
};

const logoutUser = (localThis) => {
  const auth = getAuth()
    .signOut(auth)
    .then(() => {
      nonPersistentStore.commit("setCurrentFirebaseUser", null);
      nonPersistentStore.commit("setCurrentlyLoggedInBand", undefined);
      nonPersistentStore.commit("addOrUpdateUserData", undefined);
      localThis.$router.push("/p/login");
    });
};

export {
  updateUser,
  getUser,
  getOtherUser,
  getAllUsers,
  getAllUsersInDateRange,
  createUser,
  checkIfUserExistsByEmail,
  checkIfUserExistsByUid,
  logoutUser,
};
