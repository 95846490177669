import axios from "axios";

const baseUrl = require("../../shared.js").default.apiBaseUrl;

const createEdge = async (
  actorId,
  objectId,
  objectType,
  edgeType,
  metadata
) => {
  try {
    const createData = {
      actorId: actorId,
      objectId: objectId,
      objectType: objectType,
      edgeType: edgeType,
      createdAt: new Date(),
      metadata: metadata,
    };
    const urlToQuery = `${baseUrl}/edge`;
    const { data } = await axios.post(urlToQuery, createData);
    return data;
  } catch (err) {
    console.log(err);
    return false;
  }
};

const getAllEdgesAssociatedWithObject = async (objectId, objectType) => {
  try {
    if (!objectId || !objectType) {
      throw new Error(
        "Missing required parameters in getAllEdgesAssociatedWithObject"
      );
    }
    const urlToQuery = `${baseUrl}/edge?objectId=${objectId}&objectType=${objectType}`;
    const { data } = await axios.get(urlToQuery);
    return data;
  } catch (err) {
    console.log(err);
    return false;
  }
};

const createReactionEdge = async (
  actorId,
  objectId,
  objectType,
  reactionType
) => {
  // reactionType should be one of the following: "LIKE", "UNDO-LIKE"
  return await createEdge(actorId, objectId, objectType, "REACTION", {
    type: reactionType.toUpperCase(),
  });
};

const createCommentEdge = async (actorId, objectId, objectType, comment) => {
  return await createEdge(actorId, objectId, objectType, "COMMENT", {
    comment: comment,
  });
};

export {
  createEdge,
  getAllEdgesAssociatedWithObject,
  createReactionEdge,
  createCommentEdge,
};
