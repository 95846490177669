import axios from "axios";

import { formatPost } from "./helperFunctions";
const baseUrl = require("../../shared.js").default.apiBaseUrl;

const createPost = async (createData) => {
  try {
    const urlToQuery = `${baseUrl}/post`;
    const axiosResponse = await axios.post(urlToQuery, createData);
    const { data } = axiosResponse;
    return formatPost(data);
  } catch (err) {
    console.log(err);
    return false;
  }
};

const getAllPostsOfBand = async (bandId, userId) => {
  try {
    const urlToQuery = `${baseUrl}/posts?bandId=${bandId}&userId=${userId}`;
    const axiosResponse = await axios.get(urlToQuery);
    const { data } = axiosResponse;
    return data.map((post) => formatPost(post));
  } catch (err) {
    console.log(err);
    return false;
  }
};

const deletePost = async (postId, userId) => {
  try {
    const urlToQuery = `${baseUrl}/post`;
    const axiosResponse = await axios.patch(urlToQuery, { postId, userId });
    const { data } = axiosResponse;
    return data;
  } catch (err) {
    console.log(err);
    return false;
  }
};

export { createPost, getAllPostsOfBand, deletePost };
